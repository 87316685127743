.side-btns {
	position: fixed;
	left: 0;
	top: 50%;
	transform: translateY(-50%);
	z-index: 11;
	&__item {
		width: 50px;
		height: 50px;
		display: flex;
		align-items: center;
		justify-content: center;
		@include link_no-hover(#fff);
		@include respond-to('small') {
			width: 30px;
			height: 30px;
		}
		& i {
			color: #fff;
			font-size: 22px;
			@include respond-to('small') {
				font-size: 18px;
			}
		}
		&_fb {
			background-color: #3a559f;
		}
		&_wa {
			background-color: #4caf50;
		}
		&_yt {
			background-color: #ff0000;
		}
		&_in {
			background-color: #007ab9;
		}
	}
}