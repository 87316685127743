.wrapper-banners {
	position: relative;
	width: 100%;
	height: 700px;
	overflow: hidden;
	@include respond-to('huge') {
		height: 650px;
	}
	@include respond-to('large') {
		height: 450px;
	}
	@include respond-to('extra-small') {
		height: 300px;
	}
}

.wrap-banner-scroll {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	@include respond-to('medium') {
		position: static;
	}
}

.banner-item {
	width: 100%;
	min-height: 700px;
	position: relative;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	@include respond-to('huge') {
		min-height: 650px;
	}
	@include respond-to('large') {
		min-height: 450px;
	}
	@include respond-to('extra-small') {
		min-height: 300px;
	}
	&_shadow {
		&::after {
			content: '';
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			max-width: 1464px;
			width: 100%;
			height: 100%;
			background-image: url(../images/banner-shadow.png);
			background-position: center;
			background-repeat: no-repeat;
			margin: auto;
			z-index: 1;
		}
	}
}

.banner-info {
	position: absolute;
	left: 50%;
	top: 60%;
	transform: translate(-50%, -50%);
	max-width: 540px;
	width: 100%;
	text-align: center;
	z-index: 2;
	&__title {
		margin: 0;
		line-height: 0.9;
		font-size: 50px;
		font-weight: 700;
		color: #fff;
		@include respond-to('huge') {
			font-size: 45px;
		}
		@include respond-to('1100') {
			font-size: 40px;
		}
		@include respond-to('small') {
			font-size: 35px;
		}
		@include respond-to('extra-small') {
			font-size: 30px;
		}
		& span {
			display: block;
			font-size: 40px;
			font-weight: 300;
			color: #fff;
			@include respond-to('1100') {
				font-size: 35px;
			}
			@include respond-to('small') {
				font-size: 30px;
			}
			@include respond-to('extra-small') {
				font-size: 25px;
			}
		}
	}
	&__text {
		margin-top: 20px;
		font-size: 20px;
		font-weight: 400;
		color: #fff;
		line-height: 0.9;
		@include respond-to('small') {
			font-size: 18px;
			font-weight: 400;
		}
		@include respond-to('extra-small') {
			font-size: 16px;
		}
	}
	&__btn {
		margin-top: 10px;
	}
}

.main-btn {
	display: inline-block;
	width: 160px;
	height: 50px;
	padding: 9px;
	text-align: center;
	border: 2px solid $accent;
	font-weight: 700;
	font-size: 20px;
	@include link_no-hover($accent);
	&:active {
		@include btn-effect-active();
	}
}

.custom-dots {
	& .slick-dots {
		& li {
			margin: 0 3px;
			width: 10px;
			height: 10px;
			& button {
				width: 10px;
				height: 10px;
				padding: 0;
				&::before {
					content: '';
					width: 10px;
					height: 10px;
					border-radius: 50%;
					background-color: transparent;
					border: 1px solid #fff;
					opacity: 1;
				}
			}
			&.slick-active {
				& button {
					&::before {
						background-color: #fff;
					}
				}
			}
		}
	}
}

.custom-arrow {
	& .slick-arrow {
		top: 56%;
		width: 50px;
		height: 50px;
		@include respond-to('small') {
			display: none;
		}
	}
	& .slick-next {
		#{$left}: 55px;
		@include respond-to('larger') {
			// #{$left}: 15px;
		}
		@include respond-to('small') {
			#{$left}: 15px;
		}
		&:before {
			content: url(../images/arr-l.png);
			opacity: 1;
		}
	}
	& .slick-prev {
		#{$right}: 55px;
		@include respond-to('larger') {
			// #{$right}: 15px;
		}
		@include respond-to('small') {
			#{$right}: 15px;
		}
		&:before {
			content: url(../images/arr-r.png);
			opacity: 1;
		}
	}
}