footer {
	position: relative;
	width: 100%;
	padding: 80px 0 10px;
	z-index: 1;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.15);
	@include respond-to('large') {
		padding: 40px 0 10px;
	}
	@include respond-to('medium') {
		padding: 20px 0 10px;
	}
}

.ftr-wrap {
	position: relative;
	z-index: 1;
	// & .section-limit {
	// 	max-width: 1730px;
	// }
	&__right {
		width: 25%;
		float: $right;
		@include respond-to('1100') {
			float: none;
			width: 100%;
			max-width: 300px;
			margin: 0 auto 15px;
			text-align: center;
		}
		@include respond-to('medium') {
			margin-bottom: 0;
		}
	}
	&__left {
		width: 75%;
		float: $left;
		@include respond-to('1100') {
			float: none;
			width: 100%;
			padding-top: 10px;
			padding-bottom: 30px;
		}
	}
}

.link-col {
	float: $right;
	width: 25%;
	@include respond-to('extra-small') {
		width: 50%;
		margin-bottom: 10px;
	}
}

.hr-footer-bottom {
	border: 0;
	border-top: 1px solid #dadada;
	margin-top: 40px;
	margin-bottom: 10px;
	@include respond-to('large') {
		margin-top: 20px;
		margin-bottom: 10px;
	}
	@include respond-to('extra-small') {
		margin-top: 10px;
	}
}

.ftr-title {
	font-size: 20px;
	font-weight: 700;
	color: #000;
}

.ftr-contact {
	padding-top: 25px;
	max-width: 300px;
	@include respond-to('1100') {
		margin: 10px auto 10px;
		padding-top: 15px;
	}
	@include respond-to('medium') {
		padding-top: 0;
	}
	&__item {
		display: block;
		margin-bottom: 10px;
		// text-align: $right;
		@include link_no-hover($text);
		@include respond-to('medium') {
			margin-bottom: 5px;
		}
		& img {
			display: inline-block;
			vertical-align: middle;
			width: 23px;
			margin-#{$right}: -4px;
		}
		& span {
			display: inline-block;
			vertical-align: middle;
			padding-#{$right}: 4px;
			text-align: $right;
			width: calc(100% - 23px);
			font-size: 18px;
			font-weight: 600;
			color: #000;
			@include respond-to('medium') {
				// font-size: 15px;
				// font-weight: 300;
			}
		}
	}
}

.ftr-form {
	padding: 20px 0 5px;
	&__title {
		text-align: center;
		font-size: 20px;
		font-weight: 700;
		color: #000;
	}
	&__form {
		max-width: 775px;
		width: 100%;
		margin: 30px auto 0;
		display: flex;
		justify-content: space-between;
		& .form-field {
			width: calc(100% - 145px);
			@include respond-to('extra-small') {
				width: calc(100% - 75px);
			}
			& input {
				height: 60px;
				width: 100%;
				padding: side-values(0 35px 0 10px);
				border: none;
				background-color: #f6f6f6;
				font-size: 16px;
				font-weight: 300;
				color: #000;
				@include respond-to('extra-small') {
					height: 45px;
				}
				&::placeholder {
					font-size: 16px;
					font-weight: 300;
					color: #878787;
				}
			}
		}
		& button {
			width: 130px;
			height: 60px;
			background-color: $accent;
			border: none;
			color: #fff;
			font-size: 18px;
			font-weight: 700;
			&:active {
				@include btn-effect-active();
			}
			@include respond-to('extra-small') {
				width: 70px;
				height: 45px;
			}
		}

	}
}

.site-footer__credit-holder {
	padding: 0px 0 10px;
	@include respond-to('medium') {
		padding: 0px 0 10px;
	}
}

.copyright {
	padding-top: 5px;
	text-align: center;
	font-size: 16px;
	font-weight: 700;
	color: #000;
}

.investors {
	position: relative;
	z-index: 1;
	text-align: center;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	padding: 15px 0 0;
	
	@include respond-to('medium') {
		padding: 35px 0;
	}
	@include respond-to('small') {
		padding: 25px 0;
	}
	&__title {
		font-size: 20px;
		font-weight: 700;
		color: #000;
	}
	&__slider {
		margin-top: 35px;
		@include respond-to('1100') {
			margin-top: 30px;
		}
		@include respond-to('small') {
			margin-top: 25px;
		}
	}
	&__slide-item-wrap {
		display: none;
		position: relative;
		height: 130px;
		line-height: 130px;
		padding: 0 5px;
		@include respond-to('small') {
			height: 105px;
			line-height: 105px;
		}
	}
	&__slide-item {
		display: inline-block;
		width: 100%;
		height: 100%;
		&:active,
		&:focus,
		&:hover {
			outline: none;
			& img {
				// filter: grayscale(0%);
			}
		}
		&:first-child {
			display: block;
		}
		& img {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			// display: inline-block;
			// margin: 0 auto;
			max-height: 98%;
			max-width: 98%;
			height: auto;
			// filter: grayscale(100%);
			// transition: filter 0.2s ease;
		}
	}
}

// #clients-slide {
// 	& .slick-arrow {
// 		width: 12px;
// 		height: 46px;
// 	}
// 	& .slick-prev {
// 		#{$right}: -5px;
// 		#{$left}: auto;
// 		&::before {
// 			content: if-rtl('\f054', '\f053');
// 			font-family: "Font Awesome 5 Pro";
// 			color: $accent;
// 			font-size: 25px;
// 			// font-weight: 400;
// 			// content: if-rtl(url('../images/arr-right.png'), url('../images/arr-left.png'));
// 		}
// 	}
// 	& .slick-next {
// 		#{$left}: -5px;
// 		#{$right}: auto;
// 		&::before {
// 			content: if-rtl('\f053', '\f054');
// 			font-family: "Font Awesome 5 Pro";
// 			color: $accent;
// 			font-size: 25px;
// 			// font-weight: 400;
// 			// content: if-rtl(url('../images/arr-left.png'), url('../images/arr-right.png'));
// 		}
// 	}
// }