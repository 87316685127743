.articles {
	&__item {
		border-bottom: 1px solid #d6d6d6;
		&:last-child {
			border-bottom: none;
		}
		&:first-child {
			.recommend-item {
				padding-top: 40px;
			}
		}
	}
}

.article-item {
	font-size: 0;
	padding: 60px 0;
	@include respond-to('larger') {
		padding: 50px 0;
	}
	@include respond-to('large') {
		padding: 40px 0;
	}
	@include respond-to('medium') {
		padding: 30px 0;
	}
	&__photo {
		display: inline-block;
		vertical-align: top;
		font-size: initial;
		padding-#{$left}: 10px;
		width: 19%;

		@include respond-to('large') {
			width: 22%;
		}
		@include respond-to('medium') {
			width: 26%;
		}
		@include respond-to('small') {
			width: 100%;
			border-#{$left}: none;
			margin-bottom: 0px;
			padding: 0;
			text-align: center;
		}
		& .about__img {
			position: relative;
			max-width: 230px;
			width: 100%;
			height: 190px;
			background-position: center;
			background-size: cover;
			background-repeat: no-repeat;
			@include respond-to('small') {
				margin: 0 auto 20px;
			}
			& a {
				display: block;
				width: 100%;
				height: 100%;
				background-position: center;
				background-size: cover;
				background-repeat: no-repeat;
				& img {
					margin-top: 75px;
					max-width: 90%;
					width: 100%;
					margin-left: auto;
					margin-right: auto;
				}
			}
			&::after {
				content: '';
				position: absolute;
				#{$left}: -25px;
				top: 50%;
				transform: translateY(-50%);
				width: 2px;
				height: 190px;
				background-color: #19317c;
				@include respond-to('larger') {
					#{$left}: -30px;
				}
				@include respond-to('medium') {
					#{$left}: -20px;
				}
				@include respond-to('small') {
					display: none;
				}
			}
		}
	}
	&__info {
		width: 81%;
		display: inline-block;
		vertical-align: top;
		padding-#{$right}: 50px;
		font-size: initial;
		@include respond-to('large') {
			width: 78%;
			padding-#{$right}: 40px;
		}
		@include respond-to('medium') {
			width: 74%;
			// padding-#{$right}: 50px;
		}
		@include respond-to('small') {
			width: 100%;
			text-align: center;
			padding-#{$right}: 0;
		}
	}
	&__title {
		position: relative;
		font-size: 25px;
		font-weight: 600;
		color: #000;
		line-height: 1;
		@include respond-to('small') {
			font-size: 20px;
		}
	}
	&__subtitle {
		position: relative;
		font-size: 20px;
		font-weight: 400;
		color: #8b8b8b;
		line-height: 1;
		@include respond-to('small') {
			font-size: 18px;
		}
	}
	&__text {
		margin-top: 20px;
		font-size: 18px;
		font-weight: 400;
		color: #000;
		line-height: 1.4;
		@include respond-to('medium') {
			font-size: 17px;
		}
		@include respond-to('extra-small') {
			font-size: 16px;
			text-align: justify;
		}
		& p {}
	}
	&__btn {
		display: inline-block;
		margin-top: 20px;
		@include link_on-hover(#000, $accent);
		font-size: 18px;
		font-weight: 900;
		padding-top: 8px;
		border-top: 2px solid $accent;
	}
}

.about__photo {
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
}