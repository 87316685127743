.news-item {
	display: block;
	position: relative;
	height: 362px;
	max-width: 340px;
	margin-right: auto;
	margin-left: auto;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	box-shadow: 5px 5px 15px 0 rgba($color: #000000, $alpha: 0.2);
	transition: box-shadow .2s ease;
	overflow: hidden;
	@include link_no-hover(#000);
	&:hover {
		box-shadow: 0 0 25px 0 rgba($color: #000000, $alpha: 0.5);
	}
	@include respond-to('medium') {
		height: 300px;
	}
	&__title {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		background-color: rgba($color: #fff, $alpha: 0.8);
		padding: 10px;
		font-size: 25px;
		font-weight: 900;
		color: #000;
		text-align: center;
		@include respond-to('extra-small') {
			font-size: 20px;
		}
	}
	&__link {
		font-size: 18px;
		font-weight: 400;
		color: #000;
		text-align: center;
	}
}