.main-header {
	width: 100%;
	z-index: 99;
	position: fixed;
	top: 0;
	background: #fff;
	box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.2);
	transition: all 0.4s ease;
}

.header-desktop {
	height: 115px;
	// overflow: hidden;
	@include respond-to('medium') {
		display: none;
	}
	&__top {
		height: 50px;
		background-color: $title;
	}
	&__bottom {
		height: 65px;
		text-align: center;
	}
}

.header-desktop-wr {
	max-width: 1830px;
	width: 100%;
	padding: 0 15px;
	margin: 0 auto;
	height: 100%;
}

.header-top {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 50px;
	&__right {
		max-width: 860px;
		width: 100%;
		margin-#{$left}: 20px;
	}
	&__left {
		max-width: 230px;
		width: 100%;
		@include respond-to('huge') {
			max-width: 180px;
		}
		@include respond-to('large') {
			max-width: 125px;
		}
	}
}

.logo {
	background: #fff;
	width: 480px;
	height: 100%;
	float: $right;
	padding-top: 18px;
	transform: skew(-20deg);
	margin-#{$right}: -18px;
	padding-#{$right}: 3px;
	box-shadow: 0 0 20px 0 rgba($color: $title, $alpha: 0.3);
	text-align: center;
	@include respond-to('huge') {
		width: 300px;
		padding-top: 30px;
	}
	& img {
		max-width: 340px;
		transform: skew(20deg);
		@include respond-to('huge') {
			max-width: 240px;
		}
	}
}

.main-navi {
	display: inline-block;
	max-width: 1000px;
	width: 100%;
	height: 100%;
	padding-#{$right}: 85px;
	@include respond-to('1550') {
		padding-#{$right}: 25px;
	}
	@include respond-to('large') {
		padding-#{$right}: 5px;
	}
	& > ul {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 100%;
		list-style: none;
		margin: 0;
		padding: 0 0 6px 0;
		& > li {
			// margin-#{$left}: 42px;
			position: relative;
			&:last-child {
				margin-#{$left}: 0;
			}
			&:hover {
				& .drop-menu {
					transform: scaleY(1) translateX(-50%);
					max-height: 100vh;
					opacity: 1;
				}
			}
			& > a {
				font-size: 18px;
				color: #fff;
				display: block;
				font-weight: 600;
				line-height: 1;
				@include link_no-hover(#fff);
				@include respond-to('large') {
					font-size: 17px;
				}
				&.active {}
			}
		}
	}
}

.header-btn {
	display: block;
	max-width: 230px;
	width: 100%;
	background-color: $accent;
	text-align: center;
	@include link_no-hover(#fff);
	height: 50px;
	padding: 10px;
	font-size: 20px;
	font-weight: 600;
	@include respond-to('huge') {
		font-size: 18px;
	}
	@include respond-to('large') {
		line-height: 1;
		font-size: 16px;
	}
	&:active {
		@include btn-effect-active();
	}
}

.header-bottom {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 100%;
	&__right {
		max-width: 630px;
		width: 100%;
		margin-#{$right}: 180px;
		@include respond-to('1550') {
			margin-#{$right}: 50px;
		}
		@include respond-to('larger') {
			max-width: 430px;
		}
		@include respond-to('1100') {
			max-width: 330px;
		}
	}
	&__left {}
}

.header-search {
	display: inline-block;
	// float: $left;
	height: 100%;
	width: 100%;
	// vertical-align: top;

	@include respond-to('large') {
		// margin-#{$left}: 250px;
	}
	@include respond-to('1100') {
		// margin-#{$left}: 220px;
	}
	&_mobile {
		margin-#{$left}: 0;
		float: none;
		width: 100%;
		border-left: 1px solid $accent;
		border-bottom: 1px solid $accent;
		& .search {
			width: 100%;
			& input {
				color: #949494;
				&::placeholder {
					color: #949494;
				}
			}
			& i {
				font-size: 20px;
			}
		}
	}
}

.search {
	position: relative;
	width: 100%;
	&__icon {
		position: absolute;
		#{$right}: 25px;
		top: 12px;
		color: $accent;
	}
	& input {
		width: 100%;
		border: none;
		// border-bottom: 1px solid $accent;
		padding: side-values(0px 65px 0px 130px);
		height: 40px;
		font-size: 18px;
		font-weight: 400;
		text-indent: 5px;
		background-color: #f5f5f5;
		font-style: italic;
		color: #8f8f8f;
		@include respond-to('large') {
			font-size: 16px;
			padding: side-values(0px 50px 0px 100px);
		}
		@include respond-to('medium') {
			padding: side-values(0px 10px 0px 74px);
		}
		&::placeholder {
			font-size: 18px;
			font-weight: 400;
			color: #8f8f8f;
			@include respond-to('large') {
				font-size: 16px;
			}
		}
	}
	& button {
		position: absolute;
		top: 0;
		height: 40px;
		#{$left}: 0px;
		width: 130px;
		border: none;
		background: $accent;
		text-align: center;
		padding: 6px;
		font-size: 18px;
		font-weight: 700;
		color: #fff;
		@include respond-to('larger') {
			width: 70px;
		}
		&:active {
			@include btn-effect-active();
		}
	}
}

.drop-menu {
	position: absolute;
	z-index: 10;
	#{$left}: 50%;
	// right: 0%;
	top: 35px;
	transform: scaleY(0) translateX(-50%);
	max-height: 0;
	min-width: 200px;
	width: 100%;
	opacity: 0;
	margin: 0 auto;
	transform-origin: top;
	transition: all 0.3s ease;
	background-color: rgba($color: #fff, $alpha: 1);
	box-shadow: 0 0 10px rgba($color: #000000, $alpha: 0.3);
	& > ul {
		position: relative;
		width: 100%;
		height: 100%;
		padding: 40px 35px 30px;
		list-style: none;
		margin: 0;
		&::after {
			content: '';
			position: absolute;
			left: 0;
			right: 0;
			top: -8px;
			margin: 0 auto;
			width: 0;
			height: 0;
			border-top: 0px solid transparent;
			border-right: 18px solid transparent;
			border-left: 18px solid transparent;
			border-bottom: 8px solid #fff;
		}
		& > li {
			display: block;
			margin-bottom: 12px;
			& > a {
				position: relative;
				display: block;
				flex: 1 1 100%;
				padding: side-values(5px 20px 5px 5px);
				color: #000;
				text-align: $right;
				font-size: 18px;
				font-weight: 400;
				line-height: 1;
				&:hover {
					text-decoration: none;
					color: $accent !important;
				}
				&:focus,
				&:active {
					text-decoration: none;
				}
				&::before {
					content: '';
					position: absolute;
					#{$right}: 0;
					top: 12px;
					width: 10px;
					height: 2px;
					background-color: $accent;
				}
			}
		}
	}
}

.header-mobile {
	display: none;
	height: 70px;
	@include respond-to('medium') {
		display: block;
	}
	&__logo {
		margin-#{$left}: 15px;
		margin-top: 15px;
		float: $left;
		@include respond-to('mobile-m') {
			margin-top: 15px;
			margin-#{$left}: 5px;
		}
		& > img {
			width: 170px;
			@include respond-to('mobile-m') {
				width: 170px;
			}
		}
	}
	&__tel {
		display: inline-block;
		margin-top: 17px;
		i {
			color: $accent;
			font-size: 32px;
		}
	}
}