.ftr-links-koteret {
	position: relative;
	margin-bottom: 20px;
	padding-bottom: 20px;
	border-bottom: 1px solid transparent;
	transition: border 0.2s ease;
	font-size: 20px;
	font-weight: 700;
	color: $text;
	@include respond-to('medium') {
		font-size: 18px;
		margin-bottom: 5px;
		margin-top: 10px;
		padding-bottom: 5px;
	}
	@include respond-to('extra-small') {
		font-size: 16px;
	}
}

.ftr-wrap-links {
	max-width: 200px;
	margin: 0 auto;
	@include respond-to('medium') {
		max-width: 160px;
		margin: 0 auto;
	}
	@include respond-to('small') {
		margin-bottom: 5px;
		margin-left: auto;
		margin-right: auto;
	}
	@include respond-to('mobile-l') {
		max-width: 140px;
	}
	&:hover {
		& .ftr-links-koteret {
			color: $accent;
			border-bottom: 1px solid $text;
		}
	}
	& > ul {
		padding: 0;
		margin: 0;
		list-style: none;
		& > li {
			margin-bottom: 2px;
			@include respond-to('medium') {
				line-height: 1;
			}
			& > a {
				font-size: 18px;
				font-weight: 300;
				color: #000;
				@include respond-to('medium') {
					font-size: 14px;
					line-height: 1;
				}
				@include respond-to('mobile-l') {
					// font-size: 12px;
				}
				&:hover {
					color: $accent;
					text-decoration: none;
				}
			}
		}
	}
}